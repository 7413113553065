<script setup>
import Table from '@/Components/Table/Table.vue';
import autoAnimate from '@formkit/auto-animate';
import { useForm } from '@inertiajs/vue3';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AcceptedApplicationForm from '../../Partials/AcceptedApplicationForm.vue';
import ModalWithin96Hours from '../../Partials/ModalWithin96Hours.vue';
import RejectedApplicationForm from '../../Partials/RejectedApplicationForm.vue';
import ShiftSummary from '../../Partials/ShiftSummary.vue';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    shift: Object,
    liked_flexer_ids: Object,
    sortBy: {
        type: String,
        default: '',
    },
    sortByDesc: {
        type: String,
        default: '',
    },
    isOrange: Boolean,
});

const summary = ref();

onMounted(() => {
    autoAnimate(summary.value);
});

const selectedApplication = ref({});
const showAcceptedApplicationForm = ref(false);
const openAcceptedApplicationForm = (application) => {
    showAcceptedApplicationForm.value = true;
    selectedApplication.value = application;
};

const showRejectedApplicationForm = ref(false);
const openRejectedApplicationForm = (application) => {
    showRejectedApplicationForm.value = true;
    selectedApplication.value = application;
};

const inviteForm = useForm({ application_id: '' });
const inviteApplicantToShift = (application) => {
    inviteForm.application_id = application.id;
    inviteForm.post(route('location.invited-applications.store'));
};

const openModalWithin96Hours = ref(false);

const userType = { freelancer: t('Freelancer'), temp_worker: t('Temp Worker') };

const emit = defineEmits(['update:sortBy', 'update:orderDir']);

const tableSettings = [
    { label: t('Name'), sortBy: 'flexer.first_name', width: '25%', cell: 'NameAndLike' },
    { label: t('Type'), sortBy: 'flexer.type', width: '15%' },
    { label: t('Reviews'), sortBy: 'flexer.received_reviews_avg_rating', cell: 'Rating', width: '15%' },
    { label: t('Price per hour'), width: '15%' },
    { label: t('Distance'), width: '15%' },
    { label: '', width: '2%', cell: 'Cta' },
    { label: '', width: '2%', cell: 'Remove' },
    { label: '', width: '2%', cell: 'Arrow' },
];

const applications = computed(() => [...props.shift.applications]);

const tableData = computed(() =>
    applications.value.map((application) => {
        return {
            id: application.id,
            colorCode: application.status === 'accepted' ? '#1BB21B' : '#F89B29',

            rowData: [
                [
                    application.flexer.display_name,
                    'bold',
                    application.flexer.id,
                    props.liked_flexer_ids.includes(application.flexer.id),
                ],
                userType[application.flexer.type],
                [application.flexer.received_reviews_avg_rating, application.flexer.received_reviews_count],
                '€' + (application.hourly_cost / 100).toFixed(2),

                application.distance / 1000 + ' km',
                [application, props.shift],
                application.status == 'applied' || application.status == 'confirmation_required',
                route('location.flexers.show', { id: application.flexer.id }),
            ],
        };
    })
);

const whichButton = ({ row, column, id, cell }) => {
    if (cell === 'Cta') {
        let application = props.shift.applications.find((app) => app.id == id);
        if (application.status == 'applied') {
            openAcceptedApplicationForm(application);
        } else if (application.status == 'confirmation_required') {
            inviteApplicantToShift(application);
        }
    } else if (cell === 'Remove') {
        let application = props.shift.applications.find((app) => app.id == id);
        openRejectedApplicationForm(application);
    }
};
</script>

<template>
    <div ref="summary" class="">
        <ShiftSummary :shift="shift" show-date />

        <div class="grid gap-2">
            <Table class="mb-8" :tableSettings="tableSettings" :data="tableData" @cellClicked="whichButton" />
        </div>
    </div>
    <ModalWithin96Hours v-model:showing="openModalWithin96Hours" />
    <AcceptedApplicationForm v-model:showing="showAcceptedApplicationForm" :application="selectedApplication" />
    <RejectedApplicationForm v-model:showing="showRejectedApplicationForm" :application="selectedApplication" />
</template>
